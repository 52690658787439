// preLogin.scss
.earth-card-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.emailButton {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
  font-size: large;
  border-radius: 3px;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75);
}

.landing-page-card {
  position: absolute;
  border-radius: "30px";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: "21rem";
  width: "21rem";
  margin-bottom: '5%';
  z-index: 1;
}

.landing-page-guest-link {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  margin-top: 10px;
  z-index: 1;
}

.card-fixed-width{
  width: 17rem;
}

.text-black {
  color: #000;
}

.text-blue {
  color: #007bff;
}
